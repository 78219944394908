.rootCard {
  padding: 16px;
  display: flex;
  @media (--viewport-md) {
    border-radius: 0;
  }
}

.content {
  padding-left: 24px;
}

.title {
  padding: 8px 0px;
  width: 100%;
  cursor: text;
}
