.typography {
  white-space: pre-wrap;
}

.inlineEmoji {
  img {
    height: 1em;
    width: auto;
    display: inline-block;
    position: relative;
    top: 0.1em;
  }
}
