.conversationRoot {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}

.togglePopoverButton {
  margin-right: -4px;
}

.onlineBadge {
  outline: 4px solid white;
}

.nameContainer {
  padding: 4px 12px;
}
.moreButton {
  margin-left: auto;
}
.backButton {
  margin-right: 8px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.moreMessagesButton {
  margin: 8px auto;
}

.conversationStart {
  width: 100%;
  height: 54px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyListText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 464px;
}

.bottomItem {
  min-height: 32px;
  display: table;
  width: 100%;
}

.footer {
  position: relative;
}

.jumpToBottom {
  position: absolute;
  right: 16px;
  top: -40px;
  padding: 4px;
  min-width: unset;
  z-index: 5;
}

.actionContainer {
  padding: 12px 12px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editorRoot {
  flex: 1;
  padding-right: 8px;
}

.editor {
  max-height: 128px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  :global {
    .public-DraftEditor-content {
      min-height: auto !important;
    }
  }
}

.emojiButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  margin: 0 !important;
}

.sendHelperText {
  text-align: right;
  margin: 4px 64px 8px 12px;
}

.sendButton {
  align-self: flex-end;
  margin-bottom: 8px;
}
