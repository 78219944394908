.dialogRoot {
  width: 700px;
  height: 500px;
  min-width: 700px;
}

.container {
  display: flex;
  padding: 0;
}

.screenshotImage {
  width: 215px;
  height: 430px;
  margin-top: 16px;
  // box-shadow: 36px 4px 10px rgba(0, 0, 0, 0.2);
}

.leftContainer {
  display: flex;
  flex-direction: column;
  margin-right: 36px;
  margin-left: 24px;
  margin-top: 34px;
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 24px;
  max-width: 320px;
}

.scanContainer {
  margin-top: 12px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.scanImageContainer {
  border: 1px solid var(--palette-common-grey-200);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  width: 106px;
  height: 108px;
}

.codeImage {
  width: 90px;
  height: 90px;
}

.instructionContainer {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  background-color: var(--palette-common-grey-25);
  border-radius: 8px;
  padding: 12px;
}

.instructionTitle {
  margin-bottom: 4px;
}

.storeLogoContainers {
  margin-top: 12px;
  display: flex;
}

.storeIcon {
  width: 129px;
  height: 36px;
  margin-right: 12px;
  cursor: pointer;
}
