.player {
  --plyr-color-main: var(--palette-common-blue-dark);
  width: 100%;
  :global {
    .plyr__video-wrapper {
      display: flex;
    }
    .plyr:fullscreen video {
      height: unset;
      margin: auto;
    }
  }
}
