.messageBubble {
  display: flex;
  flex-direction: column;
  margin: 4px 0px;
  max-width: 70%;
  padding: 12px 16px;
  border-radius: var(--shape-borderRadiusBig);
  word-break: break-word;
}

.messageTime {
  align-self: flex-end;
  margin-top: 4px;
  align-items: center;
  display: flex;
  user-select: none;
}

.messagesDate {
  align-self: center;
  padding: 8px 0;
  user-select: none;
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--palette-common-neutral-white);
  text-align: center;
  z-index: 1;
}

.sentBubble {
  align-self: flex-end;
  border-start-end-radius: 0;
  border: 1px solid var(--palette-divider);
  background-color: var(--palette-common-neutral-white);
}

.receivedBubble {
  align-self: flex-start;
  border-end-start-radius: 0;
  background-color: var(--palette-common-blue-darker);
  p {
    color: var(--palette-common-neutral-white);
  }
}

.notSentIcon {
  margin-right: 4px;
}

.sendingSpinner {
  margin-right: 4px;
}
