.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 250px;
  align-items: center;

  p {
    margin-top: 8px;
  }
}
