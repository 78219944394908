.dialogRoot {
  min-width: 400px;
  @media (--viewport-md) {
    min-width: 300px;
    max-width: 400px;
    width: 80vw;
    height: unset;
  }
}

.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    width: 100%;
    text-align: center;
  }
}

.crossIcon {
  margin-bottom: 12px;
  font-size: 56px;
}
