.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  padding-top: 56px !important;
  text-align: center;
  padding-bottom: 4px;
  @media (--viewport-md) {
    padding-top: 80px !important;
  }
}

.giftIcon {
  margin-top: 8px;
  min-height: 55px;
}

.successText {
  margin-top: 12px;
  align-items: center;
}

.globalDollarIcon {
  margin: auto 4px;
  margin-bottom: -6px;
}

.button {
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 126px;
}
