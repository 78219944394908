.root {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 64px 0px;
}

.imageContainer {
  position: relative;
  width: max-content;
}

.searchIconContainer {
  position: absolute;
  bottom: 2px;
  right: -12px;
  background-color: var(--palette-common-neutral-white);
  width: 42px;
  height: 42px;
  border-radius: 24px;
  border: 2px solid var(--palette-common-grey-200);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchIcon {
  font-size: 19px;
  color: var(--palette-common-grey-900);
}

.text {
  margin-top: 14px;
  margin-bottom: 4px;
}
