.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  max-width: 256px;
  margin-left: auto;
  margin-right: auto;
}

.fixedLoader {
  position: absolute;
  top: 128px;
  left: 138px;
}

.loaderContainer {
  position: relative;
}

.progress {
  position: absolute;
  width: 48px;
  top: calc(50% - 16px);
  left: calc(50% - 24px);
  text-align: center;
  font-weight: 700;
}
