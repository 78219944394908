.container {
  display: flex;
  position: relative;
  padding-left: 24px;
  padding-right: 48px;
  margin-bottom: 16px;
}

.moreButton {
  position: absolute;
  top: 4px;
  right: 8px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;
  flex: 1;
}

.infoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.commentDate {
  margin-left: 8px;
}

.loading {
  margin-left: 12px;
  min-height: 48px;
  flex: 1;
  transform: scale(1, 1);
}

.avatarLoading {
  width: 48px;
  height: 48px;
}

.loadMoreText {
  padding: 16px 16px 16px 24px;
  cursor: pointer;
}
