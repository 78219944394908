.dialogRoot {
  min-width: 400px;
  @media (--viewport-md) {
    min-width: unset;
  }
}

.logo {
  width: 180px;
  height: 66px;
}

.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    width: 100%;
    text-align: center;
  }
}

.title {
  margin: 16px 0px 24px;
}

button.upgradeButton {
  margin-top: 32px;
}

.backdrop {
  background-color: var(--palette-common-grey-400) !important;
}
