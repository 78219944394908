.dialogRoot {
  min-width: 800px;
  min-height: calc(100% - 64px);
  @media (--viewport-md) {
    min-width: unset;
  }
}

.loaderContainer {
  flex: 1;
}

.avatarLoader {
  width: 56px;
  height: 56px;
  @media (--viewport-md) {
    width: 48px;
    height: 48px;
  }
}

.dialogContentRoot {
  padding: 24px;
}
.likeItemRoot {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.likeItemContent {
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
  @media (--viewport-md) {
    padding-right: 0;
  }
}

.likeInfoTitle {
  display: inline-flex;
  align-items: center;
}
.inlineDivision {
  display: inline-flex;
  padding: 0px 8px 0 4px;
}
.division {
  display: flex;
}

.country {
  margin: 4px 0px;
}

.bio {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.divider {
  margin-top: 24px;
}

.noBioTitle {
  margin-top: 8px;
}

.sayHi {
  min-width: 120px;
  align-self: center;
  margin-left: auto;
  em-emoji {
    margin-right: 4px;
  }
  @media (--viewport-md) {
    width: 100%;
    margin-top: 16px;
  }
}

.searchBox {
  margin: 0 16px 12px;
}
