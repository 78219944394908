.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media (--viewport-md) {
    padding-bottom: calc(var(--mixins-bottomNavigation-height) + 24px);
  }
  .container {
  }
  main {
    margin-top: var(--mixins-header-minHeight);
    display: flex;
    flex-direction: row;
    flex: 1;
    @media (--viewport-md) {
      flex-direction: column;
      // padding-top: 24px;
    }

    section {
      flex: 1;
      padding-top: 40px;
      padding-bottom: 32px;
      width: 100%;
      min-width: 0;
      // calculate main section height based on footer and header height
      min-height: calc(100vh - var(--mixins-header-minHeight) - 52px);

      @media (--viewport-md) {
        padding-top: 24px;
        padding-bottom: 0px;
        min-height: unset;
      }
    }
  }
  .footer {
    text-align: center;
    padding: 0px 0px 32px;
    color: var(--palette-text-secondary);
    @media (--viewport-md) {
      display: none;
    }
  }
}

.root:global(.no-padding) {
  @media (--viewport-md) {
    padding-bottom: var(--mixins-bottomNavigation-height);
  }
}

.chatPopOver {
  box-shadow: var(--shadows-4);
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
  bottom: 0;
  right: calc(50% - 616px);
  padding: 0px;
  width: 368px;
  z-index: 1000;
  border-radius: var(--shape-borderRadius) var(--shape-borderRadius) 0 0;
  background: var(--palette-common-neutral-white);
  height: 64px;
  transition-property: height;
  transition-duration: 200ms;
  transition-timing-function: ease-out;

  @media (--viewport-lg) {
    right: 24px;
  }
}

.openedChatPopover {
  height: 60vh;
}

.lockLoader {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background: var(--palette-common-neutral-white);
}
