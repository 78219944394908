.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  word-break: break-word;
  padding: 32px;
}

.message {
  text-align: center;
}

.icon {
  font-size: 80px;
}

.subMessage {
  text-align: center;
  margin-top: 16px;
}

.divider {
  margin: 8px 0;
  width: 100%;
}

.button {
  margin-top: 16px;
}

.finalize {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 250px;
  margin: auto;
  p {
    margin-top: 16px;
  }
}

.table {
  margin-top: 16px;
}
