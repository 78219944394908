.person {
  display: flex;
  padding: 24px 0;
}

.right {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 4px;

  :global {
    .MuiTypography-root {
      padding-bottom: 4px;
    }
  }
}

.avatar {
  margin-right: 40px;
}

.content {
  width: 600px;
  min-height: 200px;
  @media (--viewport-md) {
    width: unset;
  }
}

.bookButton {
  margin-bottom: 16px;
  width: fit-content;
}
