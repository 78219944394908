.container {
  flex-basis: var(--mixins-sidebar-maxWidth);
  padding-top: 40px;
  margin-right: 64px;
  max-width: var(--mixins-sidebar-maxWidth);

  height: calc(100vh - var(--mixins-header-minHeight) - 52px);
  position: sticky;
  top: var(--mixins-header-minHeight);

  .navigation {
    padding-right: 8px;
    padding-left: 2px;
    height: 100%;
    overflow-y: auto;
    &:hover::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (--viewport-lg) {
    margin-right: 40px;
  }

  :global {
    .MuiListItem-root {
      border-radius: var(--shape-borderRadius);
    }

    .MuiListItemButton-root {
      padding: 8px 8px 8px 12px;
      border-radius: var(--shape-borderRadius);
    }

    .MuiList-root {
      margin-bottom: 16px;
    }
  }
}

.title {
  padding-left: 12px;
  margin-bottom: 8px;
}
