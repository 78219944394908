.root {
}

.notificationsListRoot {
  max-height: 500px;
  overflow: auto;
  padding-top: 0;
  &::-webkit-scrollbar {
    width: 6px;
  }
}

.header {
  padding: 12px 12px 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textItem {
  justify-content: center;
  height: 56px;
}
