.closeButton {
  position: absolute;
  right: 4px;
  top: 4px;
}

.titleWithClose {
  padding-right: 46px;
}

.dialogActions {
  padding: 24px;
  button {
    min-width: 124px;
    &.MuiButton-sizeSmall {
      min-width: 100px;
    }
  }

  & > :not(:first-of-type) {
    margin-left: 16px;
  }
}
