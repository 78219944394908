.root {
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  margin-top: 32px;
}

.mediaContainer {
  margin-top: 16px;
  display: grid;
  row-gap: 8px;
  column-gap: 8px;
  grid-template-columns: 56px 56px 56px;
  grid-template-rows: 56px 56px;
}

.dashboardMediaContainer {
  margin-top: 16px;
  display: grid;
  row-gap: 4px;
  column-gap: 4px;
  grid-template-columns: 84px 84px;
  grid-template-rows: 84px 84px;
}

.mediaItem {
  width: 56px;
  height: 56px;
  border-radius: var(--shape-borderRadiusSmall);
  outline: 1px solid var(--palette-divider);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  video {
    filter: brightness(0.7);
  }
}

.dashboardMediaItem {
  width: 84px;
  height: 84px;
  border-radius: var(--shape-borderRadiusSmall);
  outline: 1px solid var(--palette-divider);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  video {
    filter: brightness(0.7);
  }
}

.loadingItem {
  width: 56px;
  height: 56px;
  border-radius: var(--shape-borderRadiusSmall);
  transform: scale(1, 1);
}

.playIcon {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  z-index: 1;
}
