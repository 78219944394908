.cardRoot {
  // margin-top: 24px;
  padding: 0px;
  overflow: hidden;
  user-select: none;
  @media (--viewport-md) {
    // margin-top: 16px;
    border-radius: 0;
  }
}

.title {
  padding: 16px 16px 4px;
  position: relative;
  width: 100%;
}

.seeAllButtonContainer {
  height: 240px;
  display: flex;
}

.seeAllSlideButton {
  margin: auto;
  min-width: 100px;
}

.seeAll {
  position: absolute;
  top: 8px;
  right: 8px;
}

.swiperContainer {
  padding-top: 16px;
  padding-bottom: 16px;
}

.emptyList {
  padding: 24px;
  text-align: center;
}
