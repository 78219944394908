.dialogPaperRoot {
  background: transparent;
}

.content {
  padding: 56px;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    align-self: center;
  }
  @media (--viewport-md) {
    padding: 24px;
  }
}

.loading {
  position: absolute;
}

.backdrop {
  background-color: rgba(#000, 0.8) !important;
}

.closeButton {
  color: var(--palette-text-contrast);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
