.title {
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 700;
  @media (--viewport-md) {
    margin-top: 0;
    width: 250px;
  }
}

.description {
  margin-bottom: 24px;
}

.loading {
  margin-bottom: 24px;
}

.loading:last-child {
  margin-bottom: 0px;
}

.transactionLoader {
  width: 600px;
  height: 134px;
  @media (--viewport-md) {
    width: 100%;
  }
}

.transactionTitle {
  margin-top: 48px;
  margin-bottom: 24px;
  font-weight: 700;
  @media (--viewport-md) {
    margin-top: 48;
    margin-bottom: 24px;
  }
}
