.dialogPaper {
  width: 580px;
  min-width: 580px;
  @media (--viewport-md) {
    width: 100%;
    min-width: 100%;
  }
}

.dialogContent {
  padding: 0;
}

.imageContainer {
  width: 100%;
  height: 200px;
  min-height: 200px;
  background-color: var(--palette-common-grey-100);
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 40px;
}

.sharingContainer {
  display: flex;
  align-items: center;
  margin-top: 32px;
  @media (--viewport-md) {
    margin-top: 20px;
    flex-direction: column;
  }
}

.sharingCodeContainer {
  display: flex;
  align-items: center;
  @media (--viewport-md) {
    width: 100%;
  }
}

.sharingActionContainer {
  display: flex;
  align-items: center;
  @media (--viewport-md) {
    margin-top: 20px;
    width: 100%;
    padding-left: 110px;
  }
}

.divider {
  margin-top: 24px;
  margin-bottom: 32px;
}

.codeContainer {
  background-color: var(--palette-common-grey-50);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  //   padding: 8px 12px;
  padding-left: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-width: 196px;
  margin-left: 8px;
  align-items: center;
}

.linkButton {
  margin-left: 8px;
}

.referralContainer {
  display: flex;
  align-items: flex-end;
}

.referralContent {
  display: flex;
  flex-direction: column;
}

.referralItem {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 120px;
}

.referralLoading {
  margin-right: 40px;
}

.tokenContainer {
  display: flex;
  align-items: center;
}

.dollarIcon {
  margin-right: 4px;
}

.referralText {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin-right: 40px;
}

.codeLoading {
  margin-left: 8px;
}

.referralTable {
  margin-top: 48px;
}

.earnedGlobalDollarContainer {
  display: flex;
  align-items: center;
}

.icon {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

.infoIcon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  // margin-top: 3px;
  margin-bottom: -2px;
}

.statusCell {
  display: flex;
  flex-wrap: nowrap;
}

.tableInfoIcon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  // margin-top: 3px;
  margin-bottom: -2px;
  color: var(--palette-text-secondary);
}

.referralRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.referralDetails {
  min-width: 120px;
  margin-right: 40px;
}
