.container {
  display: grid;
  height: 304px;
  min-height: 304px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 12px;
  column-gap: 1px;
  row-gap: 1px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  @media (--viewport-md) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
}

.item {
  flex: 1;
  // margin-right: 1px;
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;

  video {
    object-fit: cover;
    flex: 1;
  }
}

.item:last-child {
  margin-right: 0;
}

.plusCard {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--palette-common-neutral-black);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.playIconContainer {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  border-radius: 32px;
  background-color: var(--palette-common-neutral-black);
  opacity: 0.64;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
}

.playIcon {
  font-size: 40px;
  color: var(--palette-common-neutral-white);
}
