.root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.rulesList {
  line-height: 32px;
  margin-top: 8px;
  margin-left: 32px;
  li {
    list-style-type: disc;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  em-emoji {
    margin-left: 8px;
  }
}

.closeButton {
  margin-top: 16px;
  margin-left: auto;
}
