.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  @media (--viewport-md) {
    margin-top: 0;
  }
}

.title {
  font-weight: 700;
}

.balanceLoader {
  height: 73px;
  width: 608px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.totalCard {
  border: 1px solid var(--palette-common-grey-150);
  background-color: var(--palette-common-neutral-white);
  padding: 20px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 24px;
  justify-content: flex-end;
  @media (--viewport-md) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
  }
}

.dollarIcon {
  margin-right: 8px;
  width: 36px;
  height: 36px;
}

.redeemRequestLoader {
  width: 643px;
  height: 114px;
}

.loadingReward {
  transform: none;
}

.totalLabel {
  margin-right: 16px;
  @media (--viewport-md) {
    margin-bottom: 4px;
    margin-right: 0;
  }
}
.totalValue {
  display: flex;
  align-items: center;
  @media (--viewport-md) {
  }
}

.rewardTitle {
  margin-top: 48px;
  margin-bottom: 24px;
  font-weight: 700;
  @media (--viewport-md) {
    margin-top: 48px;
    margin-bottom: 24px;
  }
}

.currencyButton {
  svg {
    margin-left: 16px;
  }
  @media (--viewport-sm) {
    display: none;
  }
}

.currencyButtonLoader {
  @media (--viewport-sm) {
    display: none;
  }
}

.currencyMenu {
  @media (--viewport-sm) {
    width: 100%;
  }
}

.balance {
  margin-top: 3px;
}

.currencyMobileButton {
  display: none;
  svg {
    margin-left: 16px;
  }
  @media (--viewport-sm) {
    display: block;
    margin-bottom: -39px;
  }
}

.currencyMobileButtonLoader {
  display: none;
  @media (--viewport-sm) {
    display: block;
    margin-bottom: -20px;
  }
}

.description {
  margin-bottom: 24px;
}
