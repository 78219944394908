.root {
  width: 60%;
  background-color: var(--palette-common-neutral-black);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media (--viewport-md) {
    width: 100%;
    max-height: 50vh;
  }
}

.swiperContainer {
  width: 100%;
  overflow: hidden;
}

.paginationContainer {
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  align-items: center;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  padding: 0px 12px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (--viewport-md) {
    height: 48px;
  }
}

.mobileVideoNavigation {
  position: initial;
}

.hideControl {
  visibility: hidden;
}

.chevronIcon {
  color: var(--palette-text-contrast);
}

.swiperRoot {
  display: flex;
  width: 100%;
  height: 100%;
  user-select: none;
  margin-left: 0px;

  .itemContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      align-self: center;
    }
  }
}

.loading {
  color: white;
  font-size: 24px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
}
