.avatarIcon {
  width: 60%;
  height: 60%;
}

.extraSmall {
  width: 24px;
  height: 24px;
}

.small {
  width: 32px;
  height: 32px;
}

.large {
  width: 56px;
  height: 56px;
}

.extraLarge {
  width: 80px;
  height: 80px;
}

.twoExtraLarge {
  width: 120px;
  height: 120px;
}

.huge {
  width: 136px;
  height: 136px;
}
