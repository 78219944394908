.dialogPaper {
  min-height: 430px;
  @media (--viewport-md) {
    min-height: unset;
  }
  max-width: 800px;
}

.table {
  margin-top: 24px;
  table {
    width: 100%;
  }

  @media (--viewport-md) {
    td {
      padding: 8px 0;
    }
  }
}

.downloadButton {
  padding: 4px;
}

.emptyTransactionList {
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.emptyTransactionHelperText {
  margin-top: 16px;
}
