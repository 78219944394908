@keyframes rotationIcon {
  from {
    background-color: var(--palette-common-neutral-white);
  }
  to {
    background-color: var(--palette-common-blue-darker);
  }
}

.root {
  display: none;
  @media (--viewport-md) {
    width: 100%;
    position: fixed;
    bottom: 56px;
    left: 0px;
    display: block;
    background-color: var(--palette-common-blue-darker);
    z-index: 9;
    animation-name: rotationIcon;
    animation-duration: 0.2s;
  }
}

@keyframes reverseRotationIcon {
  from {
    background-color: var(--palette-common-blue-darker);
  }
  to {
    background-color: var(--palette-common-neutral-white);
  }
}

.expandedRoot {
  background-color: var(--palette-common-neutral-white);
  animation-name: reverseRotationIcon;
  animation-duration: 0.2s;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  padding-left: 24px;
}

.expandedContainer {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
}

.storeIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  margin-top: 8px;
}

.storeIcon {
  width: 148px;
  height: 48px;
  margin-right: 16px;
  cursor: pointer;
}

.closeButton {
  margin-left: auto;
  padding: 0;
  .closeIcon {
    color: var(--palette-common-neutral-white);
    font-size: 20px;
  }
}

.expandedCloseButton {
  margin-left: auto;
  padding: 0;
  .expandedCloseIcon {
    font-size: 20px;
  }
}

.mobileIcon {
  margin-right: 8px;
  font-size: 32px;
  color: var(--palette-common-neutral-white);
  rect {
    stroke: var(--palette-common-neutral-white);
  }
}
