.dialogRoot {
  min-width: 600px;
  min-height: 460px;
  @media (--viewport-md) {
    min-width: unset;
  }
}

.title {
  margin-top: 24px;
  margin-bottom: 12px;
}

.gridContainer {
  margin-top: 16px;
}

.charactersHelper {
  padding: 8px 16px 0;
}

.confirmationRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkedIcon {
  font-size: 80px;
}

.confirmationTitle {
  margin: 24px 0 16px;
}

.doneButton {
  min-width: 180px;
  margin-top: 48px;
}
