.root {
  display: flex;
  flex-direction: column;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}

.buttonPaper {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  width: 73px;
  height: 32px;
  display: flex;
  padding: 4px 8px;
  background-color: var(--palette-common-neutral-white);
  border-radius: 16px;
}

.divider {
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid var(--palette-common-grey-150);
  height: 100%;
}

.button {
  cursor: pointer;
}

.disbaleButton {
  cursor: default;
}

.disableIcon {
  color: var(--palette-common-grey-200);
}

.chevronIcon {
  font-size: 24px;
}

.swiperContainer {
  width: 100%;
  overflow: hidden;
}

.swiperContainer > div {
  padding-bottom: 24px;
}

.title {
  margin-bottom: 4px;
}
