.dialogPaper {
  width: 420px;
  min-width: 420px;
  @media (--viewport-md) {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
}

.dialogContent {
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.actionContainer {
  justify-content: center;
  padding-bottom: 40px;
  @media (--viewport-md) {
    padding-bottom: 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 16px;
  padding-left: 42px;
  padding-right: 42px;
  text-align: center;
  @media (--viewport-md) {
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    padding-top: 80px;
  }
}

.amount {
  margin-top: 24px;
  margin-bottom: 24px;
  @media (--viewport-md) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.textContainer {
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.text {
  white-space: nowrap;
}

.globalDollarIcon {
  margin: auto 4px;
}

.button {
  min-width: 126px;
  margin-bottom: 40px;
  margin-top: 8px;
}
