.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
}

.title {
  margin-top: 16px;
  max-width: 310px;
  text-align: center;
  display: flex;
}

.description {
  margin-top: 4px;
  max-width: 296px;
  text-align: center;
}

.emoji {
  margin-top: 2px;
}
