.dialogRoot {
  min-width: 800px;
  @media (--viewport-md) {
    min-width: unset;
  }
}

.container {
  display: flex;
  flex-direction: row;
  @media (--viewport-md) {
    flex-direction: column-reverse;
  }
}

.infoContainer {
  flex: 1;
  padding: 16px 12px 32px;
}
.imageContainer {
  display: flex;
  margin: 12px 8px 12px;
  border-radius: var(--shape-borderRadius);
  overflow: hidden;
  width: auto;
  min-height: 160px;
}

.title {
  padding: 16px 0px 8px;
}

.telNumber {
  padding: 4px 0px;
  display: flex;
  align-items: center;
}

.telTag {
  margin-left: 12px;
}

.divider {
  margin: 16px 0;
}
