.avatarContainer {
  position: relative;
}
.flag {
  height: 1.5em;
  width: 1.5em;
  position: absolute;
  bottom: -2px;
  right: -2px;
}

.contentContainer {
  margin-left: 8px;
}
.loaderRoot {
  display: flex;
  padding: 8px 16px;
  align-items: center;
}
.loaderTextContainer {
  margin-left: 8px;
}
