.recentConversationsRoot {
  position: sticky;
  top: calc(var(--mixins-header-minHeight) + 48px);
  display: flex;
  flex-direction: column;
  min-width: 210px;
}

.moreButton {
  margin-left: auto;
}
