.root {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  height: 72px;
}

.title {
  display: flex;
  padding-left: 8px;
}

.divider {
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 16px 0px 16px;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.searchBox {
  margin: 0px 16px 8px;
}

.emptyList {
  text-align: center;
  margin: 32px 0px;
  padding: 0 32px;
}
