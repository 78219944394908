.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cards {
  display: flex;
  margin-top: 16px;
  flex-direction: column;

  button {
    flex: 1;
    padding: 0;
    justify-content: start;
    margin-bottom: 24px;
  }

  .expiry {
    margin-left: 24px;
    margin-right: 8px;
  }

  svg {
    margin-right: 4px;
  }
}
