.instalments {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  button {
    display: flex;
    flex-direction: row;
    padding: 16px;
    flex: 1;
    margin-bottom: 16px;
  }

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tag {
    height: 28px;
    padding: 4px 12px;
    margin: 4px 0;
  }

  .firstText {
    padding-right: 12px;
  }
}

.dateSection {
  margin-top: 16px;
  display: flex;
  flex-direction: row;

  @media (--viewport-md) {
    flex-direction: column;
  }

  .right {
    display: flex;
    flex-direction: column;

    > p {
      margin-bottom: 8px;
    }

    .continueButton {
      margin-top: 24px;
      width: fit-content;
    }
  }
}

.table {
  display: flex;
  justify-content: end;
  align-items: center;
  flex: 1;

  @media (--viewport-md) {
    margin: 32px 0;
    justify-content: center;
    width: 100%;

    th {
      width: 50%;
    }

    table {
      width: 100%;
    }
  }
}

.dialogContent {
  height: 500px;
  min-width: 600px;
  @media (--viewport-md) {
    height: unset;
    min-width: unset;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 16px;
  }
}

.success {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 16px;
    width: fit-content;
  }
}
