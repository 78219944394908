:root {
	--palette-common-blue-lighter: #f9fcff;
	--palette-common-blue-light: #e0f9ff;
	--palette-common-blue-medium: #00bce4;
	--palette-common-blue-dark: #0095cd;
	--palette-common-blue-darker: #003749;
	--palette-common-green-lighter: #f7ffe6;
	--palette-common-green-light: #cfffa3;
	--palette-common-green-medium: #83dd3f;
	--palette-common-green-dark: #4fa400;
	--palette-common-green-darker: #235300;
	--palette-common-red-lighter: #ffedea;
	--palette-common-red-light: #ffb3ab;
	--palette-common-red-dark: #e42b30;
	--palette-common-red-medium: #e42b30;
	--palette-common-red-darker: #93000e;
	--palette-common-orange-lighter: #ffede0;
	--palette-common-orange-light: #ffb77a;
	--palette-common-orange-dark: #ed8b18;
	--palette-common-orange-medium: #ed8b18;
	--palette-common-orange-darker: #914c00;
	--palette-common-vividMalachite-lighter: #edfff2;
	--palette-common-vividMalachite-light: #edfff2;
	--palette-common-vividMalachite-dark: #00a800;
	--palette-common-vividMalachite-medium: #00c700;
	--palette-common-vividMalachite-darker: #006e00;
	--palette-common-grey-25: #fafafa;
	--palette-common-grey-50: #f5f5f5;
	--palette-common-grey-100: #f0f0f0;
	--palette-common-grey-150: #ebebeb;
	--palette-common-grey-200: #e0e0e0;
	--palette-common-grey-250: #c7c7c7;
	--palette-common-grey-300: #b3b3b3;
	--palette-common-grey-350: #949494;
	--palette-common-grey-400: #8a8a8a;
	--palette-common-grey-450: #616161;
	--palette-common-grey-500: #424242;
	--palette-common-grey-550: #3d3d3d;
	--palette-common-grey-600: #333333;
	--palette-common-grey-700: #292929;
	--palette-common-grey-800: #1f1f1f;
	--palette-common-grey-900: #0f0f0f;
	--palette-common-neutral-white: #ffffff;
	--palette-common-neutral-black: #000000;
	--palette-primary-main: #0095cd;
	--palette-secondary-main: #4fa400;
	--palette-error-main: #e42b30;
	--palette-warning-main: #ed8b18;
	--palette-warning-contrastText: #ffffff;
	--palette-success-main: #00a800;
	--palette-info-main: #8a8a8a;
	--palette-text-primary: #0f0f0f;
	--palette-text-secondary: #8a8a8a;
	--palette-text-accent: #0095cd;
	--palette-text-contrast: #ffffff;
	--palette-divider: #e0e0e0;
	--palette-tonalOffset: 0.12;
	--palette-action-active: rgba(0, 0, 0, 0.54);
	--palette-action-hover: rgba(0, 0, 0, 0.04);
	--palette-action-hoverOpacity: 0.04;
	--palette-action-selected: rgba(0, 0, 0, 0.16);
	--palette-action-selectedOpacity: 0.16;
	--palette-action-disabled: rgba(0, 0, 0, 0.26);
	--palette-action-disabledBackground: rgba(0, 0, 0, 0.12);
	--palette-action-disabledOpacity: 0.38;
	--palette-action-focus: rgba(0, 0, 0, 0.12);
	--palette-action-focusOpacity: 0.12;
	--palette-action-activatedOpacity: 0.12;
	--breakpoints-values-xs: 0;
	--breakpoints-values-sm: 600;
	--breakpoints-values-md: 900;
	--breakpoints-values-lg: 1200;
	--breakpoints-values-xl: 1536;
	--shadows-0: none;
	--shadows-1: 0px 1px 4px rgba(0, 0, 0, 0.16);
	--shadows-2: 0px 1px 4px rgba(0, 0, 0, 0.16);
	--shadows-3: 0px 2px 12px rgba(0, 0, 0, 0.08);
	--shadows-4: 0px 2px 12px rgba(0, 0, 0, 0.2);
	--shape-borderRadius: 8px;
	--shape-borderRadiusSmall: 4px;
	--shape-borderRadiusBig: 12px;
	--mixins-toolbar-minHeight: 68px;
	--mixins-suggestionBanner-minHeight: 44px;
	--mixins-bottomNavigation-height: 56px;
	--mixins-sidebar-maxWidth: 190px;
	--mixins-header-minHeight: 68px;
	--typography-fontFamily: ProximaNova;
	--typography-fontSize: 14;
	--typography-h1-fontWeight: 400;
	--typography-h1-fontSize: 96px;
	--typography-h1-lineHeight: 96px;
	--typography-h2-fontWeight: 400;
	--typography-h2-fontSize: 60px;
	--typography-h2-lineHeight: 60px;
	--typography-h3-fontWeight: 400;
	--typography-h3-fontSize: 48px;
	--typography-h3-lineHeight: 48px;
	--typography-h4-fontWeight: 700;
	--typography-h4-fontSize: 34px;
	--typography-h4-lineHeight: 34px;
	--typography-h5-fontWeight: 400;
	--typography-h5-fontSize: 24px;
	--typography-h5-lineHeight: 32px;
	--typography-h6-fontWeight: 500;
	--typography-h6-fontSize: 20px;
	--typography-h6-lineHeight: 28px;
	--typography-subtitle1-fontWeight: 500;
	--typography-subtitle1-fontSize: 16px;
	--typography-subtitle1-lineHeight: 24px;
	--typography-subtitle1-letterSpacing: -0.03em;
	--typography-subtitle2-fontWeight: 500;
	--typography-subtitle2-fontSize: 14px;
	--typography-subtitle2-lineHeight: 20px;
	--typography-subtitle2-letterSpacing: -0.02em;
	--typography-body1-fontWeight: 500;
	--typography-body1-fontSize: 16px;
	--typography-body1-lineHeight: 20px;
	--typography-body1-letterSpacing: -0.02em;
	--typography-body2-fontWeight: 500;
	--typography-body2-fontSize: 14px;
	--typography-body2-lineHeight: 20px;
	--typography-button-fontWeight: 600;
	--typography-button-fontSize: 16px;
	--typography-button-lineHeight: 22px;
	--typography-caption-fontWeight: 500;
	--typography-caption-fontSize: 12px;
	--typography-caption-lineHeight: 16px;
	--typography-overline-fontWeight: 400;
	--typography-overline-fontSize: 12px;
	--typography-overline-lineHeight: 16px;
}
