.logo {
  width: 88px;
  max-width: 88px;
  height: 32px;
  cursor: pointer;
  background-image: url(/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.toolbar {
  max-width: calc(var(--breakpoints-values-lg) * 1px);
  width: 100%;
  margin: auto;
}

button.tripSelector {
  height: 48px;
  margin-left: 16px;
  min-width: 124px;
  border-radius: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px 4px 20px;
  .text {
    margin-right: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.emptyNavigatorContainer {
  margin: auto;
}

.tripSelectorMenu {
  min-width: 200px;

  .selected {
    background-color: var(--palette-common-grey-100);
  }
}

button.rewardMenu {
  height: 36px;
  min-width: 96px;
  border-radius: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  margin-right: 4px;
  color: var(--palette-text-primary);
  .text {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
  }
  @media (--viewport-lg) {
    display: none;
  }
}

button.iconButton {
  margin: 0 2px;
}

.linksMenu {
  width: 280px;
}

li.linkItem {
  white-space: initial;
  div.linkIcon {
    padding: 4px;
    border: 1px solid var(--palette-divider);
    margin-right: 8px;
    border-radius: var(--shape-borderRadiusSmall);

    height: 36px;
    width: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
  }
}

ul.profileMenuList {
  min-width: 216px;
  li {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  li.profileName {
    justify-content: center;
    a {
      color: var(--palette-text-primary);
    }
  }
  .avatar {
    margin: 8px auto auto;
  }
}

button.profileButton {
  margin-left: auto;
}

.avatarContainer {
  position: relative;
  margin: auto;
  width: fit-content;
}

.cropButton {
  position: absolute;
  bottom: -4px;
  right: -4px;
  z-index: 2;
  border-radius: 50%;
}

.notificationMenuPaper {
  width: 380px;
}

.notificationMenuList {
  padding: 0;
}

.stickToEnd {
  // margin-left: auto !important;
}

.logOutLoader {
  margin-right: 8px;
}

.rewardMenuLoading {
  border-radius: 48px;
}

.rewardMenuItem {
  display: flex;
  align-items: center;
  .globalDollarContainer {
    align-items: center;
    display: none;
    margin-left: 8px;
    @media (--viewport-lg) {
      display: flex;
    }
    .globalDollarIcon {
      font-size: 20px;
      margin-right: 2px;
    }
    .balanceText {
      margin-bottom: -1px;
    }
  }
}

.rewardMobileButton {
  // display: none;
  // @media (--viewport-md) {
  //   display: none;
  // }
  margin-left: auto !important;
  svg {
    height: 24px;
    width: 24px;
  }
}
