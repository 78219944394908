.root {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  height: 40px;
}

.hideInMobile {
  @media (--viewport-md) {
    display: none;
  }
}

.extraSmallSpacing {
  margin-bottom: 8px;
}

.smallSpacing {
  margin-bottom: 12px;
}

.largeSpacing {
  margin-bottom: 20px;
}

.extraLargeSpacing {
  margin-bottom: 24px;
}

.titleRoot {
  color: var(--palette-text-primary);
}

.secondary {
  color: var(--palette-text-secondary);
}

.backButton {
  margin-right: 16px;
}
