.codeAutocompletePopper {
  width: unset !important;
  max-width: 400px;
}

img.flag {
  height: 1.5em;
  width: 1.5em;
}

.lockIcon {
  position: absolute;
  right: 12px;
}
