.dialogPaper {
  width: 420px;
  min-width: 420px;
  @media (--viewport-md) {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
}

.dialogContent {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actionContainer {
  justify-content: center;
  padding-bottom: 40px;
  @media (--viewport-md) {
    padding-bottom: 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 42px;
  padding-right: 42px;
  text-align: center;
  @media (--viewport-md) {
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    padding-top: 80px;
  }
}

.amount {
  margin-top: 24px;
  margin-bottom: 24px;
  @media (--viewport-md) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.text {
  font-weight: 600;
  margin-top: 16px;
  @media (--viewport-md) {
    margin-top: 16px;
  }
}

.button {
  min-width: 126px;
  margin-top: 32px;
  margin-bottom: 40px;
}
