.rootStatusContainer {
  display: flex;
  flex-direction: column;
}

.mobileRoot {
  display: none;
  margin-bottom: 32px;
  margin-left: 12px;
  @media (--viewport-lg) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .moreButton {
    padding: 0;
  }
}

.statusText {
  margin-top: 8px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}
