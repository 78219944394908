.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paymentElement {
  min-height: 400px;
  flex: 1;
  padding-top: 12px;

  iframe {
    min-height: 400px;
  }
}
