.root {
  border-top: 1px solid var(--palette-divider);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0px;
  display: none;
  z-index: 10;
  @media (--viewport-md) {
    display: block;
  }
  :global {
    button.Mui-disabled {
      color: var(--palette-action-disabled);
      @media (--viewport-md) {
        pointer-events: auto;
      }
    }
  }
}
