.container {
  display: flex;
  padding: 20px;
  padding-left: 16px;
  margin-bottom: 24px;
  min-height: 156px;
  @media (--viewport-md) {
    margin-bottom: 16px;
  }
}

.container:last-child {
  margin-bottom: 0;
}

.invitationContainer {
  // margin-bottom: 48px;
  min-height: 168px;
  @media (--viewport-md) {
    // margin-bottom: 24px;
  }
}

.claimedContainer {
  background-color: var(--palette-common-grey-25);
}

.icon {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  align-self: flex-start;
  margin-right: 24px;
}

.content {
  display: flex;
  flex-direction: column;
}

.chevronIcon {
  margin-left: 8px;
  align-self: center;
}

.text {
  margin-top: 8px;
  margin-bottom: 12px;
}

.bottomContainer {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
}

.value {
  margin: auto 4px;
}

.rewardValue {
  margin-left: 12px;
  margin-right: 8px;
}

.dollarIcon {
  margin-left: 8px;
  margin-right: -8px;
}

.checkIcon {
  color: var(--palette-success-main);
  margin-right: 10px;
}

.crossIcon {
  color: var(--palette-error-main);
  margin-right: 10px;
}

.pendingIcon {
  margin-right: 10px;
  color: var(--palette-warning-main);
}
