.root {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 64px 0px;
}

.text {
  margin-top: 20px;
  margin-bottom: 4px;
}
