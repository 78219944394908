.container {
  display: flex;
  border: 1px solid var(--palette-common-grey-150);
  background-color: var(--palette-common-neutral-white);
  padding: 20px 16px;
  margin-bottom: 24px;
  border-radius: 4px;
  @media (--viewport-md) {
    padding: 20px;
  }
}

.imageContainer {
  position: relative;
  height: 68px;
  width: 110px;
  margin-right: 16px;
  min-width: 110px;
  @media (--viewport-md) {
    align-self: flex-start;
    width: 71px;
    height: 67px;
    min-width: 71px;
  }
}

.image {
  width: 110px;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  @media (--viewport-md) {
    flex-direction: column;
  }
}

.middleColumn {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  margin-right: 16px;
  @media (--viewport-md) {
    margin-right: 0;
    // margin-top: 12px;
  }
}

.rightContainer {
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: space-between;
  min-width: 96px;
  @media (--viewport-md) {
    margin-left: 0;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media (--viewport-md) {
    margin-bottom: 4px;
  }
}

.imageLabel {
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 20;
  display: flex;
}

.currency {
  font-size: 10px;
  line-height: 20px;
  margin-top: 2px;
  margin-right: 1px;
  font-family: Oswald;
}

.amount {
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  font-family: Oswald;
}

.voucherImageLabel {
  position: absolute;
  bottom: 4px;
  left: 8px;
  z-index: 20;
  display: flex;
  @media (--viewport-md) {
    bottom: 2px;
    left: 4px;
  }
}

.voucherAmount {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  align-items: flex-end;
  font-family: Oswald;
}

.voucherText {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Oswald;
  margin-bottom: -1px;
}
