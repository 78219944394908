.badgeRoot {
  margin-left: auto;
}
.badge {
  position: relative;
  transition: unset;
  transform: unset;
  right: 0;
}

.container {
  flex: 1;
  margin-left: 8px;
}

.name {
  margin: 2px 4px 2px 0px;
  white-space: nowrap;
  max-width: 122px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  display: flex;
  flex: 1;
  align-items: center;
}

.body {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
}

.date {
  margin-left: auto;
  min-width: 58px;
}

.loaderRoot {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .loaderTextContainer {
    margin-left: 8px;
    span {
      margin: 4px 0px;
    }
  }
}
