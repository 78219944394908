.table {
  margin-top: 24px;
  table {
    width: 100%;
  }
}

.downloadButton {
  padding: 4px;
}
