.subtitle {
  margin: 12px 0px 24px;
}

.rulesList {
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 32px;
  margin-left: 32px;
  li {
    list-style-type: disc;
  }
}
