.formCard {
  padding: 32px 24px;
  position: relative;
  @media (--viewport-md) {
    padding: 32px 24px 16px;
  }
}

.question {
  padding-top: 8px;
  display: inline-flex;
  svg {
    margin-right: 4px;
  }
}

.descriptionQuestion {
  margin-bottom: 8px;
}

.gridContainer {
  margin-top: 0px;
}

.personalDetailsGridContainer {
  margin-top: 24px;
}

.profileUploader {
  margin-bottom: 24px;
}

.charactersHelper {
  padding: 8px 16px 0;
}

.onboardingVideoContainer {
  margin-top: 24px;
  width: calc(100% + 48px);
  margin-left: -24px;
}

.hiddenField {
  display: none;
}

.resumeLink {
  cursor: pointer;
}
