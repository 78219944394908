.root {
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: var(--palette-common-blue-lighter);
}
.seenRoot {
  background-color: unset;
}
.iconContainer {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--palette-primary-main);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  position: relative;
}
.seenIconContainer {
  background-color: var(--palette-common-grey-100);
}
.contentContainer {
  padding: 0px 12px;
  flex: 1;
}
.body {
  margin-top: 4px;
}
.indicatorsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.badgeRoot {
  margin-top: 8px;
}
.badge {
  position: relative;
  transition: unset;
  transform: unset;
  right: 0;
}
.typeIcon {
  position: absolute;
  bottom: -6px;
  right: -6px;
  background: white;
  border-radius: 50%;
}

.notificationItemLoader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}
.notificationItemLoaderContent {
  margin-left: 12px;
}

.notificationPreview {
  &:hover {
    background: initial;
  }
}
