.paper {
  width: 580px;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 32px;
}
.image {
  width: 186px;
  height: 104px;
  margin-top: 40px;
}

.title {
  max-width: 490px;
  margin-top: 16px;
}

.description {
  max-width: 460px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.button {
  width: 184px;
}

.backdrop {
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.64) !important;
}

.countdownContainer {
  background-color: var(--palette-common-orange-lighter);
  padding: 4px 12px;
  border-radius: 4px;
  margin-top: 16px;
}

.countDownText {
  color: var(--palette-common-orange-medium);
}
