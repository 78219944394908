.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    min-width: 124px;
  }

  @media (--viewport-md) {
    position: sticky;
    bottom: var(--mixins-bottomNavigation-height);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 33%);
    padding: 32px 24px 16px 24px;
    width: calc(100% + 48px);
    left: 0px;
    margin-left: -24px;
    z-index: 1;
    margin-top: 0px;
  }
}

.dialogAction {
  @media (--viewport-md) {
    bottom: 0;
    padding: 32px 4px 24px 4px;
    width: auto;
    margin-left: 0px;
  }
}

.secondaryActionButton {
  margin-right: 24px;
}

.formTitleBar {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 8px;

  scroll-margin-top: calc(var(--mixins-header-minHeight) + 24px);

  .circularProgress {
    margin-right: 16px;
  }

  .formTitleText {
    flex-direction: column;
    align-self: center;
    .formTitleContainer {
      display: flex;
      align-items: center;
      .infoIcon {
        margin-left: 8px;
        font-size: 20px;
      }
    }
  }
}

.firstInList {
  // this prevents page title to hide under toolbar for the top one
  scroll-margin-top: 1000px;
}

button.reorderDialogOpener {
  margin-left: auto;
  align-self: flex-end;
}
