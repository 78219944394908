.bottomRow {
  display: flex;
  flex-direction: row;

  button {
    margin-left: 40px;
    width: 120px;
    max-height: 48px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.divider {
  margin: 20px 0;
}

.message {
  margin-top: 16px;
}
