.chatRoot {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chatRootTwoColumnLayout {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.leftContainer {
  flex: 3;
}
.rightContainer {
  flex: 5;
}

.notSelectedConversation {
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 72px;
}

.clickableChatHeader {
  cursor: pointer;
}

.title {
  cursor: pointer;
  display: flex;
  padding-left: 8px;
}

.badge {
  position: relative;
  transition: unset;
  transform: unset;
  right: 0;
  margin-left: 12px;
}

.newConversationButton {
  margin-left: auto;
}

.collapseButton {
  margin-right: -4px;
  svg {
    transition: transform 200ms ease-in-out;
  }
}

.divider {
  width: 100%;
}

.overlayLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: var(--palette-common-grey-500);
  opacity: 0.7;
  border-radius: var(--shape-borderRadius);
  border-end-end-radius: 0px;
  border-end-start-radius: 0px;
}
