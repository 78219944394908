.memberCardRoot {
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  height: 240px;
}

.avatarContainer {
  position: relative;
}

.name {
  margin-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.division {
  margin: 4px 0px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.sayHi {
  margin-top: auto;
}

.flag {
  height: 1.5em;
  width: 1.5em;
  position: absolute;
  bottom: -2px;
  right: -2px;
}
