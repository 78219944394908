.membersButton {
  margin: 8px 0px 4px 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  .moreMembersText {
    margin-left: 8px;
  }
}

.avatars {
  justify-content: start;
  padding-left: 16px;
}

.divider {
  margin: 24px 0px 8px;
}

.groupAvatarLoader {
  display: flex;
  flex-direction: row;
  margin: 8px 0px 4px 2px;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  span {
    margin-left: -8px;
    border-top: 2px solid var(--palette-common-neutral-white);
  }
}
