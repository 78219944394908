@import "autogenerated-variables.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: ProximaNova, Roboto, sans-serif;
  background-color: var(--palette-common-grey-25);
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--palette-primary-main);
}

* {
  box-sizing: border-box;
}

@media (--viewport-md) {
  *[data-hide-mobile] {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--palette-common-grey-150);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--palette-common-grey-300);
  border-radius: var(--shape-borderRadiusBig);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--palette-common-grey-350);
}

/* For hiding number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Swiper Chevrons */
.swiper-button-disabled {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  top: 0;
  height: calc(100% + 32px);
  width: 60px;
  color: #000;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 18px;
  font-weight: 800;
  background: var(--palette-common-neutral-white);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: var(--shadows-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next {
  right: 0;
  padding-right: 24px;
  justify-content: flex-end;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 90%
  );
}

.swiper-button-prev {
  left: 0;
  padding-left: 24px;
  justify-content: flex-start;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 90%
  );
}

@media (--viewport-md) {
  .no-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@font-face {
  font-family: "ProximaNova";
  src: url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "ProximaNova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "ProximaNova";
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "ProximaNova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "ProximaNova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilberto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Gilberto.woff") format("woff");
}
