.iframe {
  width: 920px;
  border: none;
  overflow: hidden;

  @media (--viewport-md) {
    width: 100%;
    height: 100%;
  }
}

.dialogPaper {
  max-width: 920px;
  width: 920px;
  height: 760px;
  overflow: hidden;
  @media (--viewport-md) {
    max-width: unset;
    height: 100%;
  }
}

.content {
  padding: 0;
  overflow: hidden;
}

.title {
  z-index: 10;
}
