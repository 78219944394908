.conversationsListRoot {
  overflow: auto;
  height: 100%;
}

.emptyConversationList {
  text-align: center;
  margin: 48px 0px;
  .helperText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    svg {
      margin: 0 4px;
    }
  }
}

.reloadButton {
  margin-top: 8px;
}
