.root {
  padding: 28px 20px;
}

.clickableRoot {
  cursor: pointer;
  &[data-hover-effect="true"]:hover {
    box-shadow: var(--shadows-4);
    background-color: var(--palette-common-neutral-white);
  }
}
