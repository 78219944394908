.content {
  min-height: 250px;
  min-width: 600px;
  @media (--viewport-md) {
    height: unset;
    min-width: unset;
  }
}

.loading {
  span {
    margin-bottom: 20px;
  }
}

.backButton {
  padding: 0 8px 0 0;
  height: 20px;
}
