.rootCard {
  padding: 16px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (--viewport-md) {
    border-radius: 0;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
}

.headerContent {
  padding-left: 12px;
}

.title {
  display: inline-flex;
}

.division {
  display: inline-flex;
  padding: 0px 8px 0 4px;
}

button.moreButton {
  position: absolute;
  top: -8px;
  right: -8px;
}

.bottomContainer {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-top: 12px;
}

.likeButton {
  margin-right: 8px;
}

.likedIcon {
  color: var(--palette-common-red-medium);
}

.divider {
  margin-top: 20px;
}

.likeNumber {
  cursor: pointer;
  min-width: 24px;
  margin-right: 16px;
}

.contentText {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.pinnedContentText {
  -webkit-line-clamp: unset;
}

.seeMoreButton {
  margin-left: auto;
  margin-top: 12px;
}

.pinContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-left: 32px;
  svg {
    margin-right: 4px;
  }
}

.pinnedCardRoot {
  background-color: var(--palette-common-blue-lighter);
}

.recentCommentItem {
  display: flex;
  flex-direction: row;
  margin: 6px 0;
  .recentCommentNickname {
    margin-right: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .recentCommentContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.viewAllCommentsButton {
  margin-left: -6px;
}

.firstReactionSection {
  padding: 16px;
  background-color: var(--palette-common-grey-100);
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-bottom: -12px;
}

.firstReactionText {
  cursor: pointer;
}
