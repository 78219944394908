.cardRoot {
  // margin-top: 24px;
  padding: 0px;
  overflow: hidden;
  user-select: none;
  @media (--viewport-md) {
    // margin-top: 16px;
    border-radius: 0;
  }
}

.cardTitle {
  margin-left: 16px;
  margin-top: 16px;
}

.postCard {
  width: 205px;
  height: 276px;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.postCardHeader {
  display: flex;

  align-items: center;
  padding: 12px;
  padding-bottom: 8px;
  width: 100%;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: auto;
  margin-right: 8px;
  max-width: 114px;
}

.postImage {
  width: 100%;
  height: 176px;
  object-fit: cover;
}

.title {
  margin: 12px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  align-self: flex-start;
}

.swiperContainer {
  padding-top: 16px;
  padding-bottom: 16px;
}

.instagramIcon {
  font-size: 16px;
  margin-left: auto;
}

.name {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.postsContainer {
  display: flex;
}

.postItem {
  @media (--viewport-md) {
    margin-left: auto;
    margin-right: auto;
  }
}

.socialTitle {
  margin-bottom: 16px;
  margin-top: 24px;
}
.titleSpace {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextButton {
  width: 34px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000029;
  border-top-right-radius: 38px;
  border-bottom-right-radius: 38px;
  cursor: pointer;
}
.prevButton {
  width: 34px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000029;
  border-top-left-radius: 38px;
  border-bottom-left-radius: 38px;
  cursor: pointer;
}
.iconSize {
  width: 16px;
}
