.dialogPaper {
  min-width: 80vw;
}

.content {
  @media (--viewport-md) {
    padding: 0;
    display: flex;
    align-items: center;
    background: var(--palette-common-neutral-black);
  }
}
