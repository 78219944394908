.formDialogRoot {
  min-width: 800px;
  @media (--viewport-md) {
    min-width: unset;
  }
}

.formDialogContent {
  padding: 0 24px 24px;
  @media (--viewport-md) {
    padding-bottom: 0px;
  }
}
