.dialogRoot {
  min-width: 730px;
  min-height: 560px;
  @media (--viewport-md) {
    min-width: unset;
  }
}

.mediaGridContainer {
  margin-top: 8px;
}

.mediaPreview {
  object-fit: cover;
  border-radius: var(--shape-borderRadius);
}

.itemContainer {
  display: flex;
  position: relative;
}

.editRibbon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 8px;
}

.editButton {
  z-index: 2;
  margin-left: 8px;
  box-shadow: var(--shadows-1);
  border-radius: 50%;
  background: var(--palette-common-neutral-white);
  &:hover {
    background: var(--palette-common-neutral-white);
    box-shadow: var(--shadows-3);
  }
}

.videoPreviewItemOverlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 8px;
  background: rgb(0 0 0 / 50%);
}

.uploadIndicator {
  display: inline-flex;
  align-items: center;
  margin-right: auto;
  p {
    margin-right: 12px;
  }
}

.emojiButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  margin: 0 !important;
}

.editorRoot {
  &::-webkit-scrollbar {
    width: 5px;
  }
}
