.profileHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.intro {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (--viewport-md) {
    padding: 0 16px;
    flex-direction: column;
  }
}

.info {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  @media (--viewport-md) {
    margin-left: 0px;
    text-align: center;
    margin-top: 16px;
  }
}

.clickableAvatar {
  cursor: pointer;
  &:hover {
    opacity: calc(1 - var(--palette-action-hoverOpacity));
  }
}

.moreMenuButton {
  align-self: baseline;
  margin-left: auto;
}

.locationAndDivision {
  display: inline-flex;
  :first-child {
    margin-right: 4px;
  }
}

.action {
  min-width: 144px;
  em-emoji {
    margin-right: 4px;
  }
  @media (--viewport-md) {
    margin: auto;
    width: 100%;
  }
}

.actionButtonContainer {
  @media (--viewport-md) {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
  }
}

.bio {
  margin: 24px 0px;
  white-space: pre-wrap;
  @media (--viewport-md) {
    padding: 0 16px;
    margin: 8px 0px 24px;
  }
}

.divider {
  margin-top: 24px;
  margin-bottom: 24px;
}

.gridContainer {
  margin-top: 0px;
}

.listInfo {
  padding: 24px 0px;
}

.createPostCard {
  margin-bottom: 24px;
}

.momentsText {
  @media (--viewport-md) {
    padding: 0 16px;
  }
}

.details {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  @media (--viewport-md) {
    margin-left: auto;
    margin-right: auto;
  }
}

.adminBadgeContent {
  svg {
    font-size: 32px;
  }
}
.adminBadge {
  bottom: 16px;
  right: 20px;
}
