.editorContainer {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.editor {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border: 1px solid var(--palette-divider);
  cursor: text;
  padding: 16px;
  padding-right: 56px;
  border-radius: var(--shape-borderRadius);
  margin-top: 2px;
  overflow: auto;
  max-height: 180px;
  &:hover {
    border-color: var(--palette-common-blue-light);
  }
  &[data-focused="true"] {
    border: 1px solid var(--palette-primary-main);
    outline: 1px solid var(--palette-primary-main);
  }
  &[data-disabled="true"] {
    border-color: var(--palette-divider);
    outline: none;
    color: var(--palette-action-disabled);
  }
}

.editor :global(.DraftEditor-root) {
  flex: 1;
}
.editor :global(.public-DraftEditorPlaceholder-root) {
  position: absolute;
  color: var(--palette-common-grey-350);
  max-width: calc(100% - 36px);
}

.editor :global(.public-DraftEditor-content) {
  min-height: 100px;
}

.emojiImageContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.suggestedEmoji {
  img {
    height: 1.5em !important;
  }
}
