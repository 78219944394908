.dialogPaper {
  width: 636px;
  min-width: 636px;
  @media (--viewport-md) {
    width: 100%;
    min-width: 100%;
  }
}

.dialogContent {
  padding: 0;
}

.imageContainer {
  width: 100%;
  height: 180px;
  min-height: 180px;
  background-color: var(--palette-common-grey-100);
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 36px;
}

.ratingContainer {
  display: flex;
  margin-top: 8px;
  margin-bottom: 32px;
}

.description {
  margin-top: 4px;
}

.textfield {
  margin-top: 16px;
  margin-bottom: 28px;
}

.uploader {
  margin-top: 16px;
  margin-bottom: 32px;
}

.actionContainer {
  justify-content: flex-start;
}

.filesContainer {
  display: flex;
  flex-direction: column;
}

.uploadFile {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 20px;
  align-items: center;
}

.fileNameContainer {
  display: flex;
  align-items: center;
}

.fileIcon {
  margin-right: 12px;
}

.rewardsContainer {
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  @media (--viewport-md) {
    flex-direction: column;
  }
}

.conditionContainer {
  display: flex;
  align-items: flex-start;
}

.termsCondition {
  color: var(--palette-primary-main);
  cursor: pointer;
}

.rewardItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--palette-common-grey-100);
  border-radius: 8px;
  width: 142px;
  min-width: 142px;
  height: 94px;
  margin-right: 16px;
  cursor: pointer;
  @media (--viewport-md) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.rewardItem:last-child {
  @media (--viewport-md) {
    margin-bottom: 0;
  }
}

.selectedRewardItem {
  border-color: var(--palette-primary-main);
}

.rewardImage {
  max-width: 108px;
}

.checkbox {
  margin-right: 8px;
  padding: 0;
}

.errorText {
  margin-top: 8px;
}

.rewardsErrorText {
  margin-top: -16px;
  margin-bottom: 24px;
}

.photosErrorText {
  margin-top: -24px;
  margin-bottom: 32px;
}

.loadingContent {
  padding: 36px 24px;
  padding-bottom: 46px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (--viewport-md) {
    padding-top: 60px;
  }
}

.loadingIcon {
  margin-bottom: 22px;
  min-height: 84px;
}

.listItem {
  display: list-item;
  color: var(--palette-text-secondary);
}

.listItem:last-child {
  margin-bottom: 16px;
}

.listItem:first-child {
  margin-top: 16px;
}

.reasonAlert {
  margin-bottom: 24px;
}

.successPaper {
  width: 390px;
  min-width: 390px;
  @media (--viewport-md) {
    width: 100%;
    min-width: 100%;
  }
}
