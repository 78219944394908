.container {
  width: 100%;
  border: 1px solid var(--palette-common-grey-150);
  background-color: var(--palette-common-neutral-white);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 4px;
  @media (--viewport-md) {
    padding: 20px;
  }
}

.redeemedContainer {
  background-color: var(--palette-common-grey-50);
}

.globalDollarContainer {
  display: flex;
  align-items: center;
}

.dollarIcon {
  margin-right: 4px;
  width: 28px;
  height: 28px;
}

.description {
  margin-top: 8px;
  margin-bottom: 12px;
  @media (--viewport-md) {
    margin-bottom: 16px;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date {
  margin-top: 4px;
  white-space: nowrap;
}
