.dialogPaperRoot {
  min-width: 600px;
  min-height: 700px;
  @media (--viewport-md) {
    min-width: unset;
  }
  @media screen and (max-height: 700px) {
    min-height: 95vh;
  }
}
.cropperContainer {
  position: relative;
  flex: 16;
  @media (--viewport-md) {
    flex: 2;
  }
}

.dialogContent {
  padding: 24px 24px 0px;
  flex: 1;
}

.controlContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.slider {
  margin: 0 16px;
  flex: 1;
  :global {
    .MuiSlider-rail {
      background: var(--palette-common-grey-300);
    }
    .MuiSlider-thumb {
      background: var(--palette-common-neutral-white);
      border: 1px solid var(--palette-divider);
      width: 24px;
      height: 24px;
    }
  }
}

.dialogActions {
  padding: 12px 24px 24px;
}
