.browseButton {
  margin: auto 6px;
  color: var(--palette-text-accent);
  outline: none;
  cursor: pointer;
}

.profileRoot {
  display: flex;
  align-items: center;
  @media (--viewport-md) {
    flex-direction: column;
  }
}

.pickerButton {
  background: transparent;

  &:hover {
    background: transparent;
    text-decoration: underline;
    text-decoration-color: var(--palette-text-secondary);
  }
  margin-left: 24px;

  @media (--viewport-md) {
    margin-left: 0px;
    margin-top: 16px;
  }
}

.mediaPickerButton {
  text-align: center;
}

.cameraIcon {
  font-size: 44px;
}

.disableButton {
  cursor: default;
  color: var(--palette-text-secondary);
}

.cropButton {
  position: absolute;
  bottom: -4px;
  right: -4px;
  z-index: 2;
  border-radius: 50%;
}

.mobileText {
  text-transform: capitalize;
}

.errorText {
  margin-left: 8px;
}
