.dialogPaper {
  max-width: 1200px;
  width: calc(100vw - 80px);
  height: 700px;
  max-height: calc(100vh - 80px);
  @media (--viewport-md) {
    max-width: unset;
    width: 100vw;
    height: 100vh;
    max-height: unset;
  }
}

.noMediaDialogPaper {
  width: 600px;
  @media (--viewport-md) {
    width: 100%;
  }
}

.dialogContent {
  display: flex;
  padding: 0;
  @media (--viewport-md) {
    flex-direction: column;
  }
}

.errorDialogContent {
  display: flex;
  padding: 0;
  flex-direction: column;
}

.errorContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    margin: 24px 0px;
  }
  button {
    min-width: 124px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 40%;
  @media (--viewport-md) {
    width: 100%;
    height: 100%;
  }
}

.noMediaContainer {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding-bottom: 16px;
  @media (--viewport-md) {
    overflow: initial;
  }
}

.topBar {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  position: sticky;
  top: 0px;
  background: var(--palette-common-neutral-white);
  z-index: 3;
}

.profileContainer {
  padding-left: 24px;
  padding-right: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;
}

.profileContent {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.titleContainer {
  display: flex;
}

.division {
  display: inline-flex;
  padding: 0px 8px 0 4px;
}

.moreButton {
  position: absolute;
  top: 4px;
  right: 8px;
}

.postText {
  padding-left: 24px;
  padding-right: 12px;
  margin-top: 12px;
  @media (--viewport-md) {
    margin-top: 16px;
  }
}

.divider {
  margin-bottom: 12px;
}

.bottomContainer {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.likeButton {
  margin-right: 8px;
}

.likedIcon {
  color: var(--palette-common-red-medium);
  font-size: 28px;
}

.likeIcon {
  font-size: 28px;
}

.likeNumber {
  cursor: pointer;
  min-width: 24px;
  margin-right: 16px;
}

.commentIcon {
  font-size: 28px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
}

.inputCommentContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: 24px;
  padding-right: 16px;
  margin-top: 16px;
  background-color: var(--palette-common-neutral-white);
}

.commentInput {
  margin-left: 12px;
  flex: 1;
}

.actionContainer {
  position: sticky;
  bottom: 0px;
  padding-bottom: 16px;
  background-color: var(--palette-common-neutral-white);
}

.contentDivider {
  margin-top: 18px;
  margin-bottom: 20px;
  margin-left: 24px;
  margin-right: 24px;
}

.emojiButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  margin: 0 !important;
}

.editorRoot {
  flex: 1;
  margin-left: 12px;
  position: relative;
  overflow: auto;
}

.sendButton {
  align-self: flex-end;
  margin-left: 8px;
  margin-bottom: 4px;
}

.commentEditor {
  padding-left: 12px;
  margin-top: 0;
}

.commentEditor > div > div > div {
  min-height: auto !important;
}

.closeIcon {
  font-size: 16px;
}

.closeButton {
  padding: 4px;
}
