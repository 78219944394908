.root {
  display: flex;
}

.right {
  flex: 2.5;
  padding-left: 64px;

  @media (--viewport-lg) {
    display: none;
  }
}

.main {
  flex: 9.5;
  min-width: 0;
}
