.tabs {
  margin: auto;
  @media (--viewport-md) {
    display: none;
  }

  .selectedTab {
    color: var(--palette-primary-main);
  }
  :global {
    .MuiButtonBase-root {
      line-height: 44px;
      opacity: 1;
      text-transform: none;
    }
    .MuiTabs-indicator {
      height: 4px;
      border-radius: var(--shape-borderRadius);
    }
    a.Mui-disabled {
      color: var(--palette-action-disabled);
    }
  }
}

.tooltip {
  max-width: 400px;
}
