.header {
  margin-bottom: 12px;
}

.banner {
  margin: 24px 0;
}

.mobileStatusIndicator {
  margin-bottom: 16px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    flex-direction: row;
    padding: 16px;
    flex: 1;
    margin-bottom: 16px;

    > :nth-child(2) {
      flex: 1;
      padding: 0 24px;

      > * {
        padding: 4px;
      }

      .tag {
        height: 28px;
        padding: 4px 12px;
      }
    }
  }
}

.rightSide {
  padding-top: 56px;
}

.actions {
  margin-top: 12px;
}
