.globalBadgeContent {
  background: var(--palette-common-neutral-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
  svg {
    font-size: 16px;
  }
}

.globalBadge {
  bottom: 6px;
  right: 6px;
}
