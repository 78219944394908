.root {
  position: fixed;
  top: var(--mixins-toolbar-minHeight);
  width: 100%;
  left: 0;
  z-index: 1099;
  height: var(--mixins-suggestionBanner-minHeight);
  background: linear-gradient(
    90deg,
    rgba(25, 119, 243, 0.5) -2.85%,
    rgba(29, 155, 240, 0.12) 102.66%
  );
  @media (--viewport-md) {
    height: unset;
    min-height: var(--mixins-suggestionBanner-minHeight);
  }
}

.paperRoot {
  background-color: var(--palette-common-neutral-white);
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.closeButton {
  margin-left: auto;
}

.text {
  margin-left: auto;
  text-align: center;
  cursor: pointer;
  a {
    cursor: pointer;
  }
}

.bannerCountDown {
  margin-left: 8px;
  color: var(--palette-common-orange-medium);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.closeIcon {
  color: var(--palette-common-grey-400);
}
